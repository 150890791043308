import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#719eb8';

const EmuPage = ({ data }) => (
  <>
    <SEO
      title="Le meilleur en ligne Neosurf Australie – Casino Emu"
      description="Rejoins Casino Emu – l’un des casinos australiens de Neosurf le mieux noté. Joue à plus de 900 machines à sous et découvres-en plus sur les Offres de Bonus exclusives."
      alternateLanguagePages={[
        {
          path: 'casino/emu',
          language: 'en'
        },
        {
          path: 'casino/emu',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Emu Casino – Bons de casino Neosurf les mieux notés" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorDark">Emu Casino – Casino de Neosurf amical pour l’Australie</h1>
            <p className="textColorLight">Emu Casino est l’un des casinos en ligne australiens le mieux noté qui accepte la méthode de dépôt Neosurf, plus d’un millier de jeux des meilleurs Fournisseurs de Jeux au monde, y compris plus de 900 machines à sous de poker, une sélection incroyable de Promotions et une assistance 24 heures sur 24 pour garantir que le Casino Emu fournira des heures et des heures d’une expérience de jeu ultime ! Une variété d’options de paiement et des Courses de Casino uniques vient s’ajouter à l’offre de cet incroyable casino ! Inscris-toi aujourd’hui et réclame ton <strong>Bonus de Bienvenue Spécial de NEOSURF</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">BONUS DE BIENVENUE DU CASINO EMU</h2>
            <p>Profite du ‘Triple Bonus de Bienvenue’ du Casino Emu</p>
            <ul className="textColorDark">
              <li>Premier dépôt – 100% de correspondance jusqu’à 100 $ de bonus en espèces</li>
              <li>Deuxième dépôt – 25% de correspondance jusqu’à 100 $ de bonus en espèces</li>
              <li>Troisième dépôt – 50% de correspondance jusqu’à 100 $ de bonus en espèces</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">OUVRIR CASINO EMU</a>
            </p>
            <p>Rends-toi simplement à la caisse, sélectionne ta méthode de dépôt et suis les instructions pour profiter de cette incroyable Offre Forfaitaire de bienvenue!</p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Bonus de casino Neosurf le mieux noté" />
        </div>
      </div>
    </div>
  </>
)

export default EmuPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/emu/neosurf_emucasino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/emu/neosurf_emucasino_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
